@import "../../lib/styles/base";

#legend {
    position: fixed;
    left: $spacing-sm;
    bottom: $spacing-sm;

    padding: $spacing-sm / 1.2;
    width: fit-content;
    border: 2px solid $light;
    border-radius: 1rem;
    background-color: #4448;

    box-shadow: 0 0 0.5rem 0.25rem #4446 inset;


    font-size: $legend-font-size;
    // font-size: $legend-font-size-lg;
    font-family: $font-family;

    // TODO: Change legend size based on breakpoints
    @include breakpoint-min("xxl") {
        padding: $spacing;
        font-size: $legend-font-size-lg;
    }
}

.product-row {
    display: flex;
    flex-direction: row;
    user-select: none;
    // width: fit-content;
    // margin: 0;

    & > span {
        display: inline-flex;
        align-items: center;
        margin-left: $spacing-sm / 1.5;
        font-family: $font-family;
        font-weight: 400;
        color: $light;

        @include breakpoint-min("xxl") {
            margin-left: $spacing;
        }
    }
}
