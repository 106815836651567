@import "../../lib/styles/base";

#costar-logo {
    position: fixed;
    right: $spacing;
    bottom: $spacing / 4;
    
    img {
        width: 15vw;
        min-width: 300px;
        max-width: 400px;
    }
}