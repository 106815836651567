$font-family: "CoStar Brown", Arial, Helvetica, sans-serif !default;

$spacing: 1.25rem !default;
$spacing-sm: $spacing / 1.5 !default;
$light: white !default;
$legend-font-size: 1rem !default;
$legend-font-size-lg: 1.75rem !default;
$banner-font-size: 1.75rem !default;

$grid-breakpoints: (
  xs: 0,
  lg: 992px,
  xxl: 2000px
) !default;

// TODO: Importing from here results in an empty object
:export {
  spacing: $spacing;
  colorLight: $light;
  breakpointXs: map-get($grid-breakpoints, "xs");
  breakpointLg: map-get($grid-breakpoints, "lg");
  breakpointXxl: map-get($grid-breakpoints, "xxl");
}