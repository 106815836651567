@import "../../lib/styles/base";

#banner {
    position: fixed;
    left: 0;
    top: 0;

    padding: $spacing/3 $spacing;
    width: 100%;
    max-height: 25vh;
    color: $light;
    background-color: #000F;
    text-align: center;

    font-size: $banner-font-size;
    font-family: $font-family;

    overflow-y: hidden;
}

.banner-image {
    background-image: url(./Banner.jpg);
    background-size: cover;
}